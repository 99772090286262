import React from "react";
import "./section4.css";
import img1 from "assets/img/section4/img1.png";
import img2 from "assets/img/section4/img2.png";

function Section4() {

    const benefits = [
        {
            title: "Build your Web 3 Product",
            deescription: "Check out our API docs to get started with your product.",
            btn_text: "Explore API Docs",
            btn_url: "https://docs.thetokenlist.com",
            img: img1,
        },
        {
            title: "Low code / No code interface",
            deescription: "If you do not have a technology team in place.",
            btn_text: "Explore Platform ",
            btn_url: "https://thetokenlist.com/create",
            img: img2,
        },
    ]

    return (
        <>
            <section id="section4" className="section4 container-fluid">
                <div className="container">

                    {/* <div>
                        <h3>
                            <span>benefits of The TokenList</span> low code web
                            3 platform{" "}
                        </h3>
                    </div> */}

                    <div className="row mt-5 feature-row">
                        {benefits.map((e, i) => (
                            <div className="col-md-6" key={i}>
                                <div className="feature-col">
                                    <h4>{e.title}</h4>
                                    <img src={e.img} alt="" />
                                    <p>{e.deescription}</p>
                                    <a href={e.btn_url} target="_blank" rel="noopener noreferrer">
                                        <button>{e.btn_text} &nbsp;{">"}</button>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </section>
        </>
    );
}

export default Section4;
